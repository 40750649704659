<!--suppress XmlUnusedNamespaceDeclaration -->
<template>
  <!--viewBox starts at (165, 104) to make up for the removal of transform="translate(-165,-104)" from the g element-->
  <svg
    class="ditto-logo"
    width="572.69208"
    height="192"
    viewBox="165 104 572.69208 192"
    preserveAspectRatio="xMidYMin"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
  >
    <g>
      <path
        d="
        M 322.867,104 H 199.133 C 180.282,104 165,119.282 165,138.133 V 261.867 C 165,280.718 180.282,296 199.133,296 H 322.867 C 341.718,296 357,280.718 357,261.867 V 138.133 C 357,119.282 341.718,104 322.867,104 Z
        "
        class="color-primary-ditto"
      />
      <path
        d="
        M 223.795,190.741 c 5.137,0 9.301,-4.164 9.301,-9.301 0,-5.137 -4.164,-9.301 -9.301,-9.301 -5.137,0 -9.302,4.164 -9.302,9.301 0,5.137 4.165,9.301 9.302,9.301 Z

        M 223.795,227.989 c 5.137,0 9.301,-4.164 9.301,-9.301 0,-5.137 -4.164,-9.302 -9.301,-9.302 -5.137,0 -9.302,4.165 -9.302,9.302 0,5.137 4.165,9.301 9.302,9.301 Z

        M 214.493,147.563 c 0,1.596 0.625,3.129 1.742,4.27 1.117,1.14 2.636,1.798 4.232,1.832 h 56.32 c 1.628,-0.006 3.242,0.31 4.748,0.929 1.506,0.619 2.875,1.53 4.028,2.679 1.154,1.15 2.069,2.516 2.694,4.02 0.624,1.504 0.946,3.116 0.946,4.745 v 68.267 c 0,1.63 -0.322,3.245 -0.946,4.751 -0.623,1.506 -1.538,2.875 -2.691,4.028 -1.153,1.153 -2.522,2.067 -4.028,2.691 -1.506,0.624 -3.121,0.946 -4.751,0.946 h -56.576 c -1.509,0 -2.957,0.596 -4.028,1.659 -1.071,1.063 -1.678,2.506 -1.69,4.015 0,3.293 1.308,6.451 3.637,8.78 2.328,2.328 5.486,3.636 8.779,3.636 h 68.267 c 3.293,0 6.451,-1.308 8.779,-3.636 2.329,-2.329 3.637,-5.487 3.637,-8.78 V 147.563 c 0,-1.637 -0.324,-3.259 -0.953,-4.771 -0.63,-1.512 -1.552,-2.884 -2.714,-4.039 -1.162,-1.154 -2.541,-2.066 -4.057,-2.685 -1.516,-0.619 -3.14,-0.932 -4.777,-0.921 h -68.267 c -3.278,0.023 -6.414,1.341 -8.724,3.667 -2.31,2.326 -3.607,5.471 -3.607,8.749 z
        "
        class="color-tertiary-ditto"
      />
      <path
        d="
        M 454.374,259.21 -2.766,-83.153 z m -2.766,-83.153 v -38.55 c 0,-1.364 0.541,-2.673 1.506,-3.637 0.964,-0.965 2.273,-1.506 3.637,-1.506 h 19.837 c 0.675,0 1.344,0.133 1.968,0.391 0.624,0.259 1.191,0.637 1.668,1.115 0.478,0.478 0.857,1.045 1.115,1.668 0.259,0.624 0.392,1.293 0.392,1.969 v 83.195 Z

        M 459.949,179.428 c 6.699,3.444 12.254,8.759 15.99,15.3 3.974,7.125 5.974,15.181 5.792,23.338 0.192,8.171 -1.808,16.244 -5.792,23.381 -3.75,6.53 -9.301,11.841 -15.99,15.299 -7.128,3.627 -15.04,5.438 -23.036,5.273 -8.03,0.135 -15.97,-1.706 -23.122,-5.359 -6.715,-3.437 -12.286,-8.753 -16.034,-15.3 -4.003,-7.099 -6.018,-15.146 -5.834,-23.294 -0.204,-8.192 1.812,-16.286 5.834,-23.425 3.736,-6.535 9.312,-11.826 16.034,-15.213 7.167,-3.604 15.102,-5.413 23.122,-5.272 7.994,-0.148 15.902,1.662 23.036,5.272 z m -33.451,25.326 c -3.073,3.731 -4.617,8.488 -4.322,13.312 -0.298,4.864 1.244,9.662 4.322,13.441 1.293,1.481 2.897,2.659 4.697,3.451 1.8,0.791 3.752,1.177 5.718,1.13 1.949,0.041 3.881,-0.357 5.655,-1.165 1.773,-0.808 3.341,-2.005 4.588,-3.503 3.053,-3.758 4.594,-8.52 4.322,-13.354 0.295,-4.824 -1.249,-9.581 -4.322,-13.312 -1.261,-1.478 -2.833,-2.659 -4.604,-3.458 -1.771,-0.8 -3.696,-1.198 -5.639,-1.166 -1.969,-0.039 -3.922,0.355 -5.722,1.154 -1.8,0.799 -3.402,1.984 -4.693,3.47 Z

        M 524.863,179.601 v 75.589 c 0,1.364 -0.542,2.672 -1.507,3.637 -0.964,0.964 -2.272,1.506 -3.636,1.506 h -19.751 c -1.361,-0.011 -2.662,-0.557 -3.624,-1.519 -0.962,-0.962 -1.508,-2.263 -1.519,-3.624 v -75.589 c 0.011,-1.36 0.557,-2.662 1.519,-3.624 0.962,-0.962 2.263,-1.507 3.624,-1.519 h 19.88 c 1.342,0.034 2.617,0.591 3.553,1.552 0.937,0.961 1.461,2.249 1.461,3.591 Z

        M 581.608,239.761 v 15.429 c 0,1.364 -0.541,2.672 -1.506,3.637 -0.964,0.964 -2.273,1.506 -3.637,1.506 h -7.347 c -8.895,0.524 -17.67,-2.263 -24.634,-7.822 -5.921,-5.187 -8.903,-13.83 -8.903,-25.931 v -27.012 -25.11 -15.645 c 0,-0.675 0.133,-1.344 0.391,-1.968 0.259,-0.624 0.638,-1.191 1.115,-1.668 0.478,-0.478 1.045,-0.857 1.669,-1.115 0.624,-0.259 1.292,-0.392 1.968,-0.392 h 19.88 c 1.364,0 2.672,0.542 3.637,1.507 0.964,0.964 1.506,2.272 1.506,3.636 v 10.502 c 0,1.364 0.542,2.672 1.507,3.637 0.964,0.964 2.272,1.506 3.636,1.506 h 5.143 c 1.364,0 2.672,0.542 3.637,1.507 0.964,0.964 1.506,2.272 1.506,3.636 v 14.824 c 0,1.364 -0.542,2.672 -1.506,3.637 -0.965,0.964 -2.273,1.506 -3.637,1.506 h -5.013 c -1.364,0 -2.672,0.542 -3.637,1.507 -0.964,0.964 -1.506,2.272 -1.506,3.636 v 22.733 c -0.103,1.011 -0.002,2.033 0.294,3.005 0.297,0.972 0.785,1.875 1.435,2.657 1.7,1.269 3.807,1.869 5.921,1.685 h 2.938 c 1.335,-0.001 2.618,0.517 3.577,1.445 0.96,0.927 1.521,2.191 1.566,3.525 Z

        M 638.268,239.761 v 15.429 c -0.012,1.361 -0.557,2.662 -1.519,3.624 -0.962,0.962 -2.264,1.508 -3.624,1.519 h -7.434 c -8.895,0.515 -17.667,-2.27 -24.634,-7.822 -5.921,-5.187 -8.903,-13.83 -8.903,-25.931 v -27.012 -25.11 -15.645 c 0,-0.675 0.133,-1.344 0.391,-1.968 0.258,-0.624 0.637,-1.191 1.115,-1.668 0.477,-0.478 1.044,-0.857 1.668,-1.115 0.624,-0.259 1.293,-0.392 1.969,-0.392 h 19.88 c 1.364,0 2.672,0.542 3.637,1.507 0.964,0.964 1.506,2.272 1.506,3.636 v 10.502 c 0,1.364 0.542,2.672 1.506,3.637 0.965,0.964 2.273,1.506 3.637,1.506 h 5.186 c 1.361,0.012 2.662,0.557 3.624,1.519 0.962,0.962 1.508,2.264 1.519,3.624 v 14.824 c 0,1.364 -0.542,2.672 -1.506,3.637 -0.965,0.964 -2.273,1.506 -3.637,1.506 h -5.186 c -1.364,0 -2.672,0.542 -3.637,1.507 -0.964,0.964 -1.506,2.272 -1.506,3.636 v 22.733 c -0.097,1.014 0.01,2.037 0.315,3.01 0.304,0.972 0.799,1.873 1.457,2.652 1.673,1.289 3.775,1.892 5.878,1.685 h 2.982 c 0.675,-0.023 1.349,0.087 1.981,0.325 0.633,0.237 1.213,0.597 1.707,1.058 0.493,0.462 0.891,1.016 1.171,1.632 0.279,0.615 0.434,1.28 0.457,1.955 Z

        M 716.061,178.694 c 6.715,3.421 12.276,8.741 15.991,15.299 3.931,7.135 5.872,15.196 5.618,23.338 0.2,8.177 -1.816,16.256 -5.834,23.381 -3.679,6.51 -9.156,11.822 -15.775,15.299 -7.104,3.659 -15.003,5.5 -22.992,5.359 -8.031,0.139 -15.971,-1.701 -23.122,-5.359 -6.722,-3.442 -12.306,-8.755 -16.077,-15.299 -4.004,-7.129 -6.005,-15.208 -5.792,-23.381 -0.218,-8.188 1.783,-16.282 5.792,-23.424 3.772,-6.521 9.358,-11.806 16.077,-15.213 7.126,-3.589 15.015,-5.398 22.992,-5.273 8.024,-0.169 15.965,1.642 23.122,5.273 z m -33.408,25.326 c -3.073,3.73 -4.617,8.487 -4.322,13.311 -0.298,4.864 1.245,9.662 4.322,13.441 1.29,1.486 2.893,2.667 4.694,3.459 1.801,0.793 3.755,1.176 5.722,1.122 1.912,0.009 3.802,-0.404 5.536,-1.211 1.733,-0.807 3.267,-1.987 4.49,-3.457 3.035,-3.768 4.574,-8.523 4.322,-13.354 0.274,-4.821 -1.268,-9.57 -4.322,-13.311 -1.297,-1.458 -2.888,-2.624 -4.668,-3.422 -1.781,-0.799 -3.71,-1.212 -5.661,-1.212 -1.951,0 -3.88,0.413 -5.66,1.212 -1.78,0.798 -3.371,1.964 -4.669,3.422 Z

        M 509.78,159.029 c 7.16,0 12.965,-5.804 12.965,-12.965 0,-7.161 -5.805,-12.966 -12.965,-12.966 -7.161,0 -12.966,5.805 -12.966,12.966 0,7.161 5.805,12.965 12.966,12.965 Z
        "
        class="color-secondary-ditto"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "DittoLogo"
}
</script>

<style scoped>
.ditto-logo {
  --color-primary-ditto: #277690;
  --color-primary-backup-ditto: #c1d334;
  --color-secondary-ditto: #212121;
  --color-secondary-backup-ditto: #595f70;
  --color-tertiary-ditto: #ffffff;
  --color-tertiary-backup-ditto: #000000;

  /*fill: #2750fc;*/
  width: var(--logo-width-client);
  height: var(--logo-height-client);
  margin-top: 5%;
  transition: transform 0.2s; /* Animation */
}

.ditto-logo:hover {
  transform: scale(1.1);
}

.ditto-logo .color-primary-ditto {
  fill: var(--color-primary-ditto);
}

.ditto-logo .color-secondary-ditto {
  fill: var(--color-secondary-ditto);
}

.ditto-logo .color-tertiary-ditto {
  fill: var(--color-tertiary-ditto);
}

.ditto-logo .color-primary-ditto.dark-mode {
  fill: var(--color-primary-backup-ditto);
}

.ditto-logo .color-secondary-ditto.dark-mode {
  fill: var(--color-secondary-backup-ditto);
}

.ditto-logo .color-tertiary-ditto.dark-mode {
  fill: var(--color-tertiary-backup-ditto);
}

.ditto-logo:hover .color-primary-ditto {
  fill: var(--color-primary-backup-ditto);
}

.ditto-logo:hover .color-secondary-ditto {
  fill: var(--color-secondary-backup-ditto);
}

.ditto-logo:hover .color-tertiary-ditto {
  fill: var(--color-tertiary-backup-ditto);
}

.ditto-logo:hover .color-primary-ditto.dark-mode {
  fill: var(--color-primary-ditto);
}

.ditto-logo:hover .color-secondary-ditto.dark-mode {
  fill: var(--color-secondary-ditto);
}

.ditto-logo:hover .color-tertiary-ditto.dark-mode {
  fill: var(--color-tertiary-ditto);
}

@media (prefers-color-scheme: dark) {
  .ditto-logo .color-primary-ditto {
    fill: var(--color-primary-backup-ditto);
  }

  .ditto-logo .color-secondary-ditto {
    fill: var(--color-secondary-backup-ditto);
  }

  .ditto-logo .color-tertiary-ditto {
    fill: var(--color-tertiary-backup-ditto);
  }

  .ditto-logo .color-primary-ditto.light-mode {
    fill: var(--color-primary-ditto);
  }

  .ditto-logo .color-secondary-ditto.light-mode {
    fill: var(--color-secondary-ditto);
  }

  .ditto-logo .color-tertiary-ditto.light-mode {
    fill: var(--color-tertiary-ditto);
  }

  .ditto-logo:hover .color-primary-ditto {
    fill: var(--color-primary-ditto);
  }

  .ditto-logo:hover .color-secondary-ditto {
    fill: var(--color-secondary-ditto);
  }

  .ditto-logo:hover .color-tertiary-ditto {
    fill: var(--color-tertiary-ditto);
  }

  .ditto-logo:hover .color-primary-ditto.light-mode {
    fill: var(--color-primary-backup-ditto);
  }

  .ditto-logo:hover .color-secondary-ditto.light-mode {
    fill: var(--color-secondary-backup-ditto);
  }

  .ditto-logo:hover .color-tertiary-ditto.light-mode {
    fill: var(--color-tertiary-backup-ditto);
  }
}
</style>
